@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Aileron, sans-serif;
  background-color: white;
}

button:focus {
  outline: none;
}

.sticky-container {
  height: 100%;
  overflow-y: auto;
}

.navLinks {
  font-size: larger;
  font-weight: bold;
  margin: 0 1rem;
}

.imghero {
  position: absolute;
  top: 90px;
  width: 500px;
  height: 500px;
}

.playbtn {
  top: 50%;
  left: 50%;
}

.getStarted {
  height: 480px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  margin-right: 2rem;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.userimg {
  width: 100%;
  height: 100%;
}

.swiper-button-next{
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev{
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev::after {
  margin-right: 20px;
  text-decoration: none;
  position: absolute;
  color: black;
}

.swiper-button-next::after {
  position: absolute;
  margin-left: 20px;
  text-decoration: none;
  color: black;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0;
} 

/* width */
::-webkit-scrollbar {
  width: 8px;
}

.faq-scrollbar::-webkit-scrollbar {
  background-color: transparent;
}

.faq-scrollbar::-webkit-scrollbar-track {
  margin-top: 50px;
  margin-bottom: 50px;
}

.faq-scrollbar::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}

/* Track */
.chat-scrollbar::-webkit-scrollbar-track {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: .3s;
  transition: .3s;
  border: 2px solid rgb(0, 0, 0, 0.1);
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 2px;
  background-color: rgb(0, 0, 0, 0.4);
  -webkit-transition: .3s;
  transition: .3s;
}

input:checked + .slider {
  background-color: #fff;
  border: 2px solid #ff6600;
}

input:checked + .slider:before {
  background-color: #ff6600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.btn-primary{

    @apply
    bg-primary
    disabled:bg-primary/50
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;    
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

@media only screen and (max-width: 768px) {
  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.2em;
  }
  
  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.2em;    
  }
}